var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"VerticalMenuItem",class:[
    true ? 'VerticalMenuItem--level' + _vm.level : '',
    _vm.showSubcategories ? 'VerticalMenuItem--open' : ''
  ]},[_c('div',{staticClass:"VerticalMenuItem__linkWrapper"},[_c('AppLink',{staticClass:"VerticalMenuItem__link",class:[
        true ? 'VerticalMenuItem__link--level' + _vm.level : '',
        _vm.link.children && _vm.link.children.length > 0 ? 'VerticalMenuItem__link--hasChildren' : '',
        _vm.showSubcategories ? 'VerticalMenuItem__link--active' : ''
      ],attrs:{"link":_vm.link}},[_vm._v(" "+_vm._s(_vm.link.title)+" ")]),(_vm.link.children)?_c('span',{staticClass:"VerticalMenuItem__togglerIconWrapper",on:{"click":function($event){_vm.showSubcategories = !_vm.showSubcategories}}},[_c('svgicon',{attrs:{"icon":_vm.toggleIcon}})],1):_vm._e()],1),(_vm.link.children)?_c('div',{staticClass:"VerticalMenuItem__children",class:{'VerticalMenuItem__children--open':_vm.showSubcategories}},_vm._l((_vm.link.children),function(child,index){return _c('VerticalMenuItem',{key:`${_vm.level}${_vm.link.id}${child.id}${index}`,attrs:{"level":_vm.level+1,"current-breadcrumbs":_vm.currentBreadcrumbs,"link":child}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }