import LogoutButton from '@theme/components/user/LogoutButton'
import VerticalMenuItem from '@theme/components/shop/VerticalMenuItem'

export default {
  components: { LogoutButton, VerticalMenuItem },
  props: {
    currentBreadcrumbs: Array,
    links: {
      type: Array,
      required: true,
    },
    showLogoutButton: Boolean,
    variant: String,
  },
}
