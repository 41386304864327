import AppLink from '@theme/components/atom/AppLink'

export default {
  components: {
    AppLink,
    VerticalMenuItem: () => import('@theme/components/shop/VerticalMenuItem'),
  },
  name: 'VerticalMenuItem',
  props: {
    level: {
      type: Number,
      default: 2,
    },
    link: {
      type: Object,
      required: true,
    },
    currentBreadcrumbs: Array,
  },
  data() {
    return {
      showSubcategories: this.currentBreadcrumbs ? this.currentBreadcrumbs.indexOf(this.link) !== -1 : false,
    }
  },
  computed: {
    toggleIcon() {
      return this.showSubcategories ? require('@icon/up.svg?raw') : require('@icon/down.svg?raw')
    },
  },
}
